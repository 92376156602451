<template>
  <AppHeader/>
  <main class="content">
    <router-view/>
  </main>
  <AppFooter/>

  <AppOverlay/>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import { defineComponent } from 'vue'
import AppOverlay from '@/components/feature/AppOverlay.vue'

export default defineComponent({
  components: { AppOverlay, AppFooter, AppHeader }
})

</script>

<style lang="scss">
#app {

}
</style>
