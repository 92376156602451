import { createRouter, createWebHistory } from 'vue-router'

const DEFAULT_TITLE = 'ClearGo - Химчистка в день обращения'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/Index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: 50,
        block: 'start'
      }
    }
    return savedPosition || { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  document.title = DEFAULT_TITLE
  next()
})

export default router
