<template>
  <div class="mobile-menu" :class="{ 'mobile-menu--open': isOpen }">
    <ul class="mobile-menu__list">
      <li class="mobile-menu__item">
        <router-link class="mobile-menu__link" to="/">Калькулятор</router-link>
      </li>
      <li class="mobile-menu__item">
        <router-link class="mobile-menu__link" to="/">Наши работы</router-link>
      </li>
      <li class="mobile-menu__item">
        <router-link class="mobile-menu__link" to="/">Химчистка</router-link>
      </li>
      <li class="mobile-menu__item">
        <router-link class="mobile-menu__link" to="/">Услуги</router-link>
      </li>
    </ul>

    <div class="mobile-menu__contact">
      <a href="tel:89017736226" class="mobile-menu__phone">
        <font-awesome-icon :icon="faPhone" />
        +7 (901) 773-62-26
      </a>
      <div class="mobile-menu__socials">
        <a href="https://t.me/ClearGoRuBot" target="_blank" class="mobile-menu__socials-link" aria-label="Telegram">
          <font-awesome-icon :icon="faTelegram"/>
        </a>
        <a href="https://api.whatsapp.com/send/?phone=79017736226&text=Здравствуйте%2C+у+меня+есть+вопрос"
           target="_blank" class="mobile-menu__socials-link" aria-label="WhatsApp">
          <font-awesome-icon :icon="faWhatsapp"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
export default defineComponent({
  name: 'AppMobileMenu',
  components: { FontAwesomeIcon },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      faTelegram,
      faWhatsapp,
      faPhone
    }
  }
})
</script>

<style lang="scss" scoped>
.mobile-menu {
  display: flex;
  flex-direction: column; // Позволит управлять блоками по вертикали
  position: fixed;
  top: 0;
  right: -100%;
  width: 300px;
  height: 100%;
  margin: 0;
  padding-top: 70px;
  background-color: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  transition: right 0.4s ease-in-out;
  z-index: 1020;
  overflow-y: auto;

  &.mobile-menu--open {
    right: 0;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item {
    width: 100%;
    border-top: 1px solid #F2F2F2;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 1px solid #F2F2F2;
    }
  }

  &__link {
    display: block;
    width: 100%;
    padding: 15px 20px;
    text-align: left;
    font-size: 18px;
    color: #333;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background-color: #e2e2e2;
      color: #000;
    }

    &:active {
      background-color: #d1d1d1;
    }
  }

  &__contact {
    margin-top: auto;
    padding: 20px 0;
    text-align: center;
    border-top: 1px solid #F2F2F2;
  }

  &__phone {
    display: block;
    font-size: 20px;
    color: #232323;
    font-weight: bold;
    margin-bottom: 15px;

    &:hover {
      color: #000;
    }
  }

  &__socials {
    display: flex;
    justify-content: center;
    gap: 10px;

    &-link {
      font-size: 22px;
      transition: color 0.3s ease;

      &:first-child {
        color: #0088cc;

        &:hover {
          color: #66c6f2;
        }
      }

      &:last-child {
        color: #25d366;

        &:hover {
          color: #6fe89e;
        }
      }
    }
  }
}
</style>
