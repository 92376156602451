<template>
  <div :class="['overlay', { 'overlay--visible': isOverlayVisible }]"></div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppOverlay',
  computed: {
    ...mapGetters(['isOverlayVisible'])
  }
})
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.overlay--visible {
  opacity: 1;
  visibility: visible;
}
</style>
