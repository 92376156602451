import { createStore } from 'vuex'

export default createStore({
  state: {
    isOverlayVisible: false
  },
  getters: {
    isOverlayVisible: state => state.isOverlayVisible
  },
  actions: {
    toggleOverlay ({ commit }) {
      commit('TOGGLE_OVERLAY')
    }
  },
  mutations: {
    TOGGLE_OVERLAY (state) {
      state.isOverlayVisible = !state.isOverlayVisible
    }
  },
  modules: {}
})
